.watch-party-type-join {
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  display: inline !important;
  text-decoration: underline !important;
  color: inherit !important;
}

.watch-party-btn svg{
  max-height: 24px;
}

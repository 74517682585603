.sd-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(58, 53, 91, .8);
    backdrop-filter: blur(3px);
}

.sd-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 20px;
    background-color: rgba(0, 0, 0, .5);
    z-index: 10;
    color: #FFFFFF;
    text-align: center;
}

@media (max-width: 1024px) {
    .sd-container .uda-input-container {
      max-width: calc(80vw - 24px);
    }
  }
.poll-manager {
    /*width: 380px;*/
    /*background: #002055;*/
    /*border-radius: 0 0 8px;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.poll-manager .poll-question,
.poll-manager .poll-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

.poll-manager .poll-remove {
    background-color: #f44336; /* Red */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px 4px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.poll-manager .poll-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.poll-manager .poll-input:disabled,
.poll-manager .poll-question:disabled {
    color: rgba(255, 255, 255, 0.5);
}

.poll {
    color: rgba(255, 255, 255, 0.5);
}


.session-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.session-container.subscriber-page {
    padding: 0;
    background-color: rgba(58, 53, 91, .8);
}

.session-container.is-session-page {
    padding: 0 8px;
}

.session-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.session-container::-webkit-scrollbar-thumb {
    background: transparent;
}

.s-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.s-logo {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    max-width: 1920px;
    margin: 0 auto;
}

.s-logo__sceenic {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}

.s-logo__sceenic-logo {
    width: 150px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 150px;
    background-image: url(./components/img/sceenic-white.svg);
}

@media (max-width: 400px) {
    .s-logo {
        height: 40px;
    }
    .s-logo__sceenic-label {
        display: none;
    }
    .s-logo__sceenic-logo {
        background-size: 120px;
        width: 120px;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    width: 100%;
    padding: 4px 8px;
    background: linear-gradient(135deg,#002055,#004ecc);
    border-radius: 32px;
    color: #FFFFFF;
    transform: scale(1);
    transition: 0.2s ease;
    outline: none !important;
    font-family: 'Rosario', sans-serif;
    border: none;
}
.button:hover:enabled {
    transform: scale(1.02);
}
.button.outline {
    border: 1px solid #B5C9FF;
    background: none;
}

.is-session-page .s-body {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 16px;
    flex-grow: 1;
    /* max-width: 1680px; */
    align-self: center;
}

.subscriber-page .s-body {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
}

@media (max-width: 1200px) {
    .is-session-page .s-body {
        margin-top: 8px;
    }
}

.s-celebrity-container {
    position: relative;
}

.s-celebrity-video {
    width: 100%;
}

.s-snapshot {
    position: absolute;
    bottom: 4px;
    left: 16px;
    right: 16px;
}

@media (max-width: 640px) {
    .s-snapshot {
        position: absolute;
        bottom: 4px;
        left: 0px;
        right: 0px;
    }

    .s-snapshot .button {
        border-radius: 0;
    }
}

.is-session-page .s-activity-container {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 12px;
    height: fit-content;
    width: 100%;
    min-width: 200px;
    margin-bottom: 16px;
}

@media (orientation: portrait) {
    .is-session-page .s-activity-container {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 1200px) {
    .is-session-page .s-activity-container {
        min-width: 800px;
        grid-gap: 4px;
    }
}

@media (min-width: 1600px) {
    .is-session-page .s-activity-container {
        width: 90%;
    }
}

@media (min-width: 1921px) {
    .is-session-page .s-activity-container {
        width: 100%;
        min-width: 800px;
        max-width: 4096px;
    }
}


.s-player-container {
    width: 100%;
    position: relative;
}

.s-participants-wrapper {
    background-color: rgba(255, 255, 255, .2);
    border-radius: 8px;
    display: grid;
    height: 100%;
}

.is-session-page .s-participants {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    padding: 8px;
    height: fit-content;
}

@media (max-width: 400px) {
    .is-session-page .s-participants {
        grid-template-columns: 1fr 1fr;
        gap: 4px;
    }
}

.s-controls button {
    margin-right: 8px;
}

.is-session-page .s-controls {
    align-items: center;
    justify-content: center;
    display: flex;
}

.s-powered-by {
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    font-size: 16px;
    margin-bottom: 4px;
}

@media (max-width: 640px) {
    .s-powered-by {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .s-powered-by {
        font-size: 8px;
    }
}

.s-powered-by__label {
    color: #cecece;
}

.s-disconnect-btn {
    width: 32px;
    outline: none;
    border: none;
    height: 32px;
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/logout.svg');
}

.s-invite-btn {
    width: 32px;
    outline: none;
    border: none;
    height: 32px;
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/invite.svg');
}

.s-invite-btn__done {
    background-image: url('../../assets/invite-done.svg');
}

.s-player-notification {
    position: absolute;
    top: 10px;
    right: 16px;
}

.player-chat-wrapper {
  position: relative;
    overflow: hidden;
}

.poll-container {
    display: block;
    bottom: 0;
    padding: 10px;

    color: white;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 8px;

    width: 100%;
    z-index: 2;
}

.poll-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    z-index: 3;
}

.poll-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.option {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}

.reactions-container {
    /*position: absolute;*/
    display: flex;
    left: 0;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    /*transform: translateX(-100%); !* Hide it on the right side *!*/
    /*transition: transform 0.3s ease-out; !* Add a transition effect *!*/
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.reaction {
    margin-right: 10px;
    font-size: 24px;
    cursor: pointer;
}
.counter {
    color: white;
    margin-right: 10px;
    font-size: 12px
}

.reactions-container.visible {
    transform: translateX(0); /* Show it when the 'visible' class is applied */
    transition: transform 0.3s ease-out; /* Add a transition effect */
}

.reaction:hover {
    transform: scale(1.2); /* Scale up the size */
    transition: transform 0.3s ease-out; /* Add a transition effect */
}

.reactions-button {
  position: absolute;
  top: 50%;
  left: 10px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.reaction-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.player-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.player {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.sync-info {
  position: absolute;
  z-index: 99;
  background: rgba(0, 0, 0 , .6);
  color: white;
  letter-spacing: 1px;
  left: 8px;
  top: 8px;
  padding: 4px 10px;
  border-radius: 8px;
}

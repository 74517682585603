.cdn-video-wrapper {
    width: 50%;
}

.subscribe-button {
    width: 30%;
    margin-top: 12px;

}

.subscriber-video {
    width: 100%;
    height: 50%;
    margin-top: 12px;
    border-radius: 8px;
}

.subscriber-page .subscriber-video {
    margin-top: 0;
    max-height: 96vh;
}

.subscriber-video-preview {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.subscriber-page .player-container {
    border: 2px solid #829ab1;
    padding: 12px;
    height: 100%;
}

.subscriber-video-placeholder {
    color: #FFFFFF;
    font-size: 24px;
    margin-bottom: 12px;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.s-player-container {
    text-align: center;
}

.subscriber-video-wrapper {
    position: relative;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;
    overflow: hidden;
}
.join-celebrity {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 1px 1px 2px #cecece, 0 0 6px #cecece, 0 0px 0px #fff;
}

.latency-info {
  position: absolute;
  z-index: 99;
  background: rgba(0, 0, 0 , .6);
  color: white;
  letter-spacing: 1px;
  left: 8px;
  top: 8px;
  padding: 4px 10px;
  border-radius: 8px;
}

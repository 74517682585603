.session-container {
    width: 100%;
    height: 100vh;
    padding: 16px 16px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.session-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.session-container::-webkit-scrollbar-thumb {
    background: transparent;
}

.is-celebrity-page .s-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.s-logo {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    max-width: 1920px;
    margin: 0 auto;
}

.s-logo__sceenic {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}

.s-logo__sceenic-logo {
    width: 150px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 150px;
    background-image: url(./components/img/sceenic-white.svg);
}

@media (max-width: 400px) {
    .s-logo {
        height: 40px;
    }
    .s-logo__sceenic-label {
        display: none;
    }
    .s-logo__sceenic-logo {
        background-size: 120px;
        width: 120px;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    width: 100%;
    padding: 4px 8px;
    background: linear-gradient(135deg,#002055,#004ecc);
    border-radius: 32px;
    color: #FFFFFF;
    transform: scale(1);
    transition: 0.2s ease;
    outline: none !important;
    font-family: 'Rosario', sans-serif;
    border: none;
}
.button:hover:enabled {
    transform: scale(1.02);
}
.button.outline {
    border: 1px solid #B5C9FF;
    background: none;
}

.is-celebrity-page .s-body {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 16px;
    flex-grow: 0;
    align-self: center;
}

@media (max-width: 1200px) {
    .is-celebrity-page .s-body {
        /*width: auto;*/
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 1801px) {
    .is-celebrity-page .s-body {
        width: 1600px;
    }
}

@media (min-width: 2561px) {
    .is-celebrity-page .s-body {
        width: 2560px;
    }
}

@media (min-width: 3400px) {
    .is-celebrity-page .s-body {
        width: 3200px;
    }
}

.is-celebrity-page .s-celebrity-container {
    position: relative;
    width: 25%;
    min-width: 15%;
    /*width: 440px;*/
    /*min-width: 440px;*/
}

/*@media (max-width: 1200px) {*/
/*    .is-celebrity-page .s-celebrity-container {*/
/*        min-width: 200px;*/
/*        max-width: 440px;*/
/*        width: 90%;*/
/*    }*/
/*}*/

/*@media (min-width: 2561px) {*/
/*    .is-celebrity-page .s-celebrity-container {*/
/*        width: 560px;*/
/*        min-width: 560px;*/
/*    }*/
/*}*/

/*@media (min-width: 3400px) {*/
/*    .is-celebrity-page .s-celebrity-container {*/
/*        width: 720px;*/
/*        min-width: 720px;*/
/*    }*/
/*}*/

.s-celebrity-video {
    width: 100%;
}

.s-snapshot {
    position: absolute;
    bottom: 4px;
    left: 16px;
    right: 16px;
}

@media (max-width: 640px) {
    .s-snapshot {
        position: absolute;
        bottom: 4px;
        left: 0px;
        right: 0px;
    }

    .s-snapshot .button {
        border-radius: 0;
    }
}

.s-activity-container {
    width: 90%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

@media (min-width: 1200px) {
    .s-activity-container {
        width: 50%;
    }
}

/*@media (min-width: 1800px) {*/
/*    .s-activity-container {*/
/*        width: 80%;*/
/*        min-width: 800px;*/
/*    }*/
/*}*/

/*@media (min-width: 1600px) {*/
/*    .s-activity-container {*/
/*        width: 60%;*/
/*    }*/
/*}*/

/*@media (min-width: 1921px) {*/
/*    .s-activity-container {*/
/*        width: 61%;*/
/*        min-width: 800px;*/
/*        max-width: 2200px;*/
/*    }*/
/*}*/


.s-player-container {
    width: 100%;
    overflow: hidden;
}

.is-celebrity-page .s-participants {
    margin-bottom: 12px;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
}

.s-disconnect-btn {
    width: 32px;
    outline: none;
    border: none;
    height: 32px;
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/logout.svg');
}

.is-celebrity-page .s-logout {
    position: absolute;
    left: -44px;
    top: 0;
    width: 32px;
    outline: none;
    border: none;
    height: 32px;
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/logout-white.svg');
}

.s-celebrity-controls {
    background: #002055;
    border-radius: 8px;
    position: relative;
    margin-top: 12px;
    padding: 16px;

}

.canvas-cdn-preview {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
}
.canvas-wrapper {
    overflow: hidden;
    border-radius: 8px;
}

.tools {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

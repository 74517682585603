.react-tabs {
    width: 100%;
}
.react-tabs__tab:focus:after {
    background: none;

}

.react-tabs__tab {
    background: transparent;
    color: white;
}

.react-tabs__tab--selected {
    background: #002055;
    /*border: 1px solid #cbcbcb;*/
    /*border-bottom: none;*/
    color: white;
    border: none;
}

.react-tabs__tab-list {
    margin: 0;
    border: none;
}

.react-tabs__tab-panel--selected {
    background: #002055;
    /*border: 1px solid #cbcbcb;*/
    /*border-top: none;*/
    padding: 16px;
    border-radius: 0 0 8px 8px;
    max-width: 100%;
}

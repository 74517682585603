.chat-container {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    min-height: 50vh;
    height: 50vh;
    overflow-y: auto;
    font-family: Arial, sans-serif;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
}

.messages {
    flex: 1;
    overflow-y: auto;
    overflow-wrap: break-word;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.messages::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.message {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.message-username {
    color: #6441a5;
    font-weight: bold;
    margin-right: 10px;
}

.message-content {
    color: #ddd;
    flex-grow: 1;
    white-space: pre-wrap;
    text-align: left;
}

.message.self .message-username {
    color: #66aaf7;
}

.chat-input {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    border: 1px solid #6441a5; /* Add a border */
    border-radius: 5px;
    background: transparent;
    color: white;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border: 1px solid #ddd;
    font-size: 16px;
}


.flying-reactions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none; /* Allow clicks to pass through */
}

.flying-reaction {
  position: absolute;
  left: 50%; /* Start from the middle of the screen */
  font-size: 2em; /* Make the reaction bigger */
  animation: fly 5s linear;
}

@keyframes fly {
  0% {
    transform:   translateY(0) rotate(0deg) scale(1);
    opacity: 0.9;
  }
  10% {
    transform:   translateY(-10vh) rotate(15deg) scale(2);
    opacity: 0.8;
  }
  20% {
    transform:   translateY(-20vh) rotate(-15deg) scale(3);
    opacity: 0.75;
  }
  30% {
    transform:   translateY(-30vh) rotate(15deg) scale(4);
    opacity: 0.6;
  }
  40% {
    transform:   translateY(-40vh) rotate(-15deg) scale(5);
    opacity: 0.5;
  }
  50% {
    transform:   translateY(-50vh) rotate(15deg) scale(6);
    opacity: 0.3;
  }
  60% {
    transform:   translateY(-60vh) rotate(-15deg) scale(7);
    opacity: 0.3;
  }
  70% {
    transform:   translateY(-70vh) rotate(15deg) scale(8);
    opacity: 0.3;
  }
  80% {
    transform:   translateY(-80vh) rotate(-15deg) scale(9);
    opacity: 0.3;
  }
  90% {
    transform:   translateY(-90vh) rotate(15deg) scale(10);
    opacity: 0.3;
  }
  100% {
    transform:   translateY(-100vh) rotate(0deg) scale(11);
    opacity: 0.3;
  }
}
